var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm.hasPages
      ? _c("div", { staticClass: "col col-12 mini-pagination" }, [
          _c("div", { class: _vm.rowClass }, [
            _c(
              "div",
              {
                staticClass: "col col-3 text-left pl-0 pr-0",
                staticStyle: { "padding-top": "10px" },
              },
              [
                _c("span", { staticClass: "bold" }, [_vm._v("Page")]),
                _vm._v(
                  " " +
                    _vm._s(_vm.currentPage) +
                    " of\n                " +
                    _vm._s(_vm.pagination.total_pages) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col col-8 text-right pr-0" }, [
              _c("ul", { staticClass: "links m-0" }, [
                _c("li", { staticClass: "previous" }, [
                  _c(
                    "a",
                    {
                      class: {
                        "btn btn-link btn-sm pt-0 pb-0": true,
                        disabled: !_vm.currentPage || _vm.currentPage === 1,
                      },
                      attrs: { href: "/" },
                      on: { click: _vm.previous },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-angle-left",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "next" }, [
                  _c(
                    "a",
                    {
                      class: {
                        "btn btn-link btn-sm pt-0 pb-0": true,
                        disabled:
                          !_vm.currentPage ||
                          !_vm.pagination ||
                          !_vm.pagination.total_pages ||
                          _vm.currentPage === _vm.pagination.total_pages,
                      },
                      attrs: { href: "/" },
                      on: { click: _vm.next },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-angle-right",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }