<template>
    <div class="row">
        <div v-if="hasPages" class="col col-12 mini-pagination">
            <div :class="rowClass">
                <div
                    class="col col-3 text-left pl-0 pr-0"
                    style="padding-top: 10px"
                >
                    <span class="bold">Page</span> {{ currentPage }} of
                    {{ pagination.total_pages }}
                </div>
                <div class="col col-8 text-right pr-0">
                    <ul class="links m-0">
                        <li class="previous">
                            <a
                                href="/"
                                :class="{
                                    'btn btn-link btn-sm pt-0 pb-0': true,
                                    disabled: !currentPage || currentPage === 1,
                                }"
                                @click="previous"
                            >
                                <i
                                    class="fa fa-angle-left"
                                    aria-hidden="true"
                                />
                            </a>
                        </li>
                        <li class="next">
                            <a
                                href="/"
                                :class="{
                                    'btn btn-link btn-sm pt-0 pb-0': true,
                                    disabled:
                                        !currentPage ||
                                        !pagination ||
                                        !pagination.total_pages ||
                                        currentPage === pagination.total_pages,
                                }"
                                @click="next"
                            >
                                <i
                                    class="fa fa-angle-right"
                                    aria-hidden="true"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        attributes: {
            type: [String, Object],
            default: '',
        },
        'margin-class-name': {
            type: String,
            default: '',
        },
    },
    data() {
        let links = null;
        let meta = null;
        let pagination = null;
        let currentPage = 0;
        let hasPages = false;
        let rowClass = 'row';

        if (this.attributes) {
            ({ links, meta } = this.attributes);

            if (meta && meta.pagination) ({ pagination } = meta);

            if (pagination && pagination.current_page) {
                currentPage = pagination.current_page;
            }

            if (
                links &&
                pagination &&
                pagination.total_pages &&
                pagination.total_pages > 1
            ) {
                hasPages = true;
            }
        }

        if (this.marginClassName) {
            rowClass += ` ${this.marginClassName}`;
        }

        return {
            hasPages,
            currentPage,
            links,
            meta,
            pagination,
            rowClass,
        };
    },
    methods: {
        previous(e) {
            e.preventDefault();

            if (!this.currentPage || this.currentPage === 1) return;

            this.$emit(
                'goToPageAction',
                Number.parseInt(this.currentPage, 10) - 1,
            );
        },
        next(e) {
            e.preventDefault();

            if (
                !this.currentPage ||
                this.currentPage === this.pagination.total_pages
            )
                return;

            this.$emit(
                'goToPageAction',
                Number.parseInt(this.currentPage, 10) + 1,
            );
        },
    },
};
</script>

<style scoped>
.mini-pagination {
    font-size: 14px;
}

ul.links {
    list-style: none;
}

ul.links li {
    display: inline;
}

ul.links li a {
    font-size: 18px;
}
</style>
