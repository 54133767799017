<template>
    <div :class="containerClass">
        <div class="bold">{{ searchTitle }}:</div>

        <template v-if="letter">
            <a :href="route" class="sort-letter">Any</a>
        </template>

        <template v-else>
            <span class="sort-letter bold">Any</span>
        </template>

        <span v-for="(alpha, idx) in alphaArr" :key="alpha + '_' + idx">
            <span
                v-if="letter && letter === alpha"
                class="pl-2 sort-letter bold"
            >
                {{ alpha }}
            </span>

            <a
                v-else
                :id="`filter-employee-last-name-${alpha}`"
                :href="route + '?letter=' + alpha"
                class="pl-2 sort-letter"
            >
                {{ alpha }}
            </a>
        </span>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        letter: {
            type: String,
            default: '',
        },
        route: {
            type: String,
            required: true,
        },
        'container-class': {
            type: String,
            default: '',
        },
    },
    data() {
        const alphas = 'abcdefghijklmnopqrstuvwxyz';
        const alphaArr = alphas.toUpperCase().split('');
        let searchTitle = this.title || 'Last Name Filter';

        return {
            alphaArr,
            searchTitle,
        };
    },
};
</script>
<style scoped>
.sort-letter {
    font-size: 15px;
}
</style>
