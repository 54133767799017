<template>
    <b-form-select
        :id="filterKey"
        v-model="selectedItem"
        :name="filterKey"
        :options="filterOptions"
        class="form-control d-inline"
        :disabled="isSubmitting"
        @change="submitFilter"
    />
</template>
<script>
import { parseData } from '../utils/xhr';
export default {
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        'filter-key': {
            type: String,
            required: true,
        },
        'id-key': {
            type: String,
            default: 'id',
        },
        options: {
            type: String,
            required: true,
        },
        'submit-route': {
            type: String,
            required: true,
        },
        'default-text': {
            type: String,
            default: null,
        },
        'auto-submit': {
            type: Boolean,
            default: true,
        },
        'use-label-as-value': {
            type: Boolean,
            default: false,
        },
        'keep-text-value': {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const filterOptions = [];
        let filterList = [];

        if (this.defaultText) {
            filterOptions.push({
                text: this.defaultText,
                value: '',
            });
        }

        if (this.options) {
            filterList = parseData(this.options);

            if (filterList) {
                let filterListArr = filterList;
                let filterListValueArr = filterList;

                // convert to array
                if (!Array.isArray(filterList)) {
                    filterListArr = [];
                    filterListValueArr = [];

                    Object.keys(filterList).map((idx) => {
                        filterListArr.push(filterList[idx]);
                        filterListValueArr.push(idx);
                    });
                }

                if (Array.isArray(filterListArr) && filterListArr.length) {
                    filterListArr.map((val, idx) => {
                        if (val && typeof val === 'object') {
                            let text = null;

                            if (val.name) text = val.name;

                            if (!text && val.title) text = val.title;

                            if (!text) text = '-';

                            filterOptions.push({
                                value:
                                    val && val[`${this.idKey}`]
                                        ? val[`${this.idKey}`]
                                        : idx,
                                text,
                            });
                        } else {
                            let value = idx;

                            if (this.useLabelAsValue) {
                                value = val;
                            }

                            if (
                                this.keepTextValue &&
                                filterListValueArr.length
                            ) {
                                value = filterListValueArr[idx];
                            }
                            filterOptions.push({
                                value,
                                text: val,
                            });
                        }
                    });
                }
            }
        }

        return {
            selectedItem: this.value,
            filterOptions,
            filterList,
            isSubmitting: false,
        };
    },
    methods: {
        submitFilter(val) {
            if (!this.autoSubmit) return;

            this.isSubmitting = true;

            this.$loading.show();

            if (!val) {
                // if there is a defaultText present, remove the query from destination url
                if (this.defaultText) {
                    window.location = `${this.submitRoute}`;
                } else {
                    window.location = `${this.submitRoute}?${this.filterKey}=-1`;
                }
            } else {
                window.location = `${this.submitRoute}?${this.filterKey}=${val}`;
            }
        },
    },
};
</script>
