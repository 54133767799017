<template>
    <div>
        <select
            v-if="!isAdding"
            :id="id"
            :value="value"
            :disabled="isLoadingItems"
            class="form-control"
            @change="changeAction"
        >
            <option value="">-- select --</option>
            <option
                v-for="item in options"
                :key="item.id"
                :value="item.id"
                :selected="item.selected"
            >
                {{ item.label }}
            </option>
            <option v-if="canAddNewItem" value="-1">
                {{ getNewItemLabel }}
            </option>
        </select>
        <div v-if="isAdding" class="col-12 p-0">
            <div class="row m-0">
                <div class="col-8 pl-0 pr-2">
                    <input
                        ref="newItemInput"
                        v-model.trim="$v.form.name.$model"
                        type="text"
                        :placeholder="newItemPlaceholder"
                        class="form-control"
                    />
                    <div
                        v-if="$v.form.name.$dirty && $v.form.name.$error"
                        class="input-error"
                    >
                        {{ getNameErrorMessage }}
                    </div>
                    <div
                        v-if="hasError && hasMessage && formMessage"
                        class="input-error"
                    >
                        {{ formMessage }}
                    </div>
                </div>
                <div v-if="!isSubmitting" class="col-4 pl-0 pr-0">
                    <button class="btn btn-info" @click="addNewItem">
                        <i class="fa fa-check" />
                    </button>
                    <button class="btn btn-secondary" @click="cancelAddNewItem">
                        <i class="fa fa-close" />
                    </button>
                </div>
                <div v-else class="col-5 text-left pt-2 pl-0">
                    <loading-icon title="adding item..." />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const { required, minLength } = require('vuelidate/lib/validators');
import { MSG_GENERIC_ERROR } from '../config/constants';
import xhrError from '../utils/xhr';

export default {
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        canAddNewItem: {
            type: Boolean,
            default: true,
        },
        addRoute: {
            type: String,
            default: '',
        },
        listRoute: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        let itemLabel = 'item';

        if (this.type) {
            if (this.type === 'incident_type') {
                itemLabel = 'type';
            } else {
                itemLabel = this.type;
            }
        }

        return {
            isAdding: false,
            isSubmitting: false,
            hasError: false,
            formMessage: null,
            isLoadingItems: false,
            newItemPlaceholder: `enter ${itemLabel} name`,
            options: [],
            itemLabel,
            form: {
                name: '',
            },
            chanSelectionTimeout: null,
        };
    },
    computed: {
        getNameErrorMessage: function () {
            return 'Required!';
        },
        getNewItemLabel: function () {
            return `Add new ${this.itemLabel}`;
        },
    },
    mounted() {
        this.loadItems();
    },
    updated() {
        const { value } = this;

        if (value && this.id) {
            const elmObj = document.getElementById(this.id);

            if (elmObj) {
                if (value !== '-1') {
                    let selectedOpt;
                    const optionsCount = elmObj.options.length;

                    for (let i = 0; i < optionsCount; i += 1) {
                        const opt = elmObj.options[i];

                        if (opt && opt.value === value) {
                            selectedOpt = opt;
                            break;
                        }
                    }

                    if (selectedOpt) {
                        elmObj.selectedIndex = selectedOpt.index;

                        // this.$emit('changeAction', this.id, value);
                    }
                } else {
                    elmObj.selectedIndex = 0;
                    this.$emit('changeAction', this.id, '');
                }
            }
        }
    },
    beforeDestroy() {
        if (this.chanSelectionTimeout) clearTimeout(this.chanSelectionTimeout);
    },
    validations() {
        return {
            form: {
                name: {
                    required,
                    minLength: minLength(2),
                },
            },
        };
    },
    methods: {
        changeAction(evt) {
            if (evt.target.value && evt.target.value === '-1') {
                this.isAdding = true;
                this.form.name = '';

                // focus input
                this.chanSelectionTimeout = setTimeout(() => {
                    if (this.$refs.newItemInput)
                        this.$refs.newItemInput.focus();

                    this.$emit('isAddingItem', true);
                }, 100);
            }

            this.$emit('changeAction', this.id, evt.target.value);
        },
        loadItems(selectedItem = null) {
            if (this.listRoute) {
                this.isLoadingItems = true;

                const options = [];

                window.axios
                    .get(this.listRoute)
                    .then((result) => {
                        if (result && result.data && result.data.length) {
                            result.data.map((item) => {
                                const option = {
                                    id: item.id,
                                    label: item.name,
                                };

                                if (
                                    selectedItem &&
                                    selectedItem.id === item.id
                                ) {
                                    Object.assign(option, { selected: true });

                                    // set caller's item id
                                    this.$emit(
                                        'changeAction',
                                        this.id,
                                        item.id,
                                    );
                                }

                                options.push(option);

                                return item;
                            });
                        }
                    })
                    .catch((err) => {
                        const errBag = xhrError(err);
                        const errMsg = errBag
                            ? errBag.message
                            : MSG_GENERIC_ERROR;

                        window.toastr.error(errMsg);
                    })
                    .finally(() => {
                        if (this.type === 'event') {
                            options.push({ id: -2, label: 'Others' });
                        }

                        this.options = options;

                        this.isLoadingItems = false;
                    });
            }
        },
        addNewItem(e) {
            e.preventDefault();

            this.hasError = false;
            this.hasMessage = false;
            this.formMessage = null;

            this.$v.$touch();

            if (this.$v.$invalid) return false;

            this.isSubmitting = true;

            const data = this.form;

            window.axios
                .post(this.addRoute, data)
                .then((result) => {
                    this.$emit('isAddingItem', false);

                    this.loadItems(result.data);

                    this.isAdding = false;
                })
                .catch((err) => {
                    this.hasError = true;
                    this.hasMessage = true;

                    const errBag = xhrError(err);

                    this.formMessage = errBag
                        ? errBag.message
                        : MSG_GENERIC_ERROR;
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        },
        cancelAddNewItem() {
            this.form.name = '';
            this.isAdding = false;

            this.$emit('isAddingItem', false);
        },
    },
};
</script>
