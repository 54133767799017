/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-select/dist/vue-select.css';

import Vue from 'vue';
import VuejsDialog from 'vuejs-dialog';
import Vuelidate from 'vuelidate';
import Loading from 'vue-loading-overlay';
import { BootstrapVue } from 'bootstrap-vue';
import VueClipboard from 'vue-clipboard2';
import vSelect from 'vue-select';
import VueI18n from 'vue-i18n';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

import { setupVueI18n } from './caregiver/virtual-interview/shared-utils';

// only enable bugsnag if a key is present
if (process.env.MIX_BUGSNAG_API_KEY) {
    const bugsnagClient = Bugsnag.start({
        apiKey: process.env.MIX_BUGSNAG_API_KEY,
        releaseStage: process.env.NODE_ENV,
        plugins: [new BugsnagPluginVue()]
    });

    const bugsnagVue = bugsnagClient.getPlugin('vue');

    bugsnagVue.installVueErrorHandler(Vue);

    window.bugsnagClient = bugsnagClient;
}

Vue.use(VuejsDialog);
Vue.use(Vuelidate);
Vue.use(Loading);
Vue.use(BootstrapVue);
Vue.use(VueI18n);

VueClipboard.config.autoSetContainer = true;
// Vue.config.silent = true;

Vue.use(VueClipboard);

Vue.prototype.$eventHub = new Vue(); // Global event bus

window.Vue = Vue;

// Misc. common components
Vue.component(
    'SelectableAdd',
    require('./components/SelectableAdd.vue').default,
);
Vue.component('SearchForm', require('./components/Search.vue').default);
Vue.component('Pagination', require('./components/Pagination.vue').default);
Vue.component('VSelect', vSelect);
Vue.component(
    'LetterSearchFilter',
    require('./components/LetterSearchFilter.vue').default,
);
Vue.component('SelectFilter', require('./components/SelectAndGo.vue').default);
Vue.component('LoadingIcon', require('./components/LoadingIcon.vue').default);
// Vue.component('ConfirmOTP', require('./components/ConfirmOTP.vue').default);

// Activation Button
Vue.component('ActivationButton', () =>
    import(
        /* webpackChunkName: "activation-button" */ './components/ActivationButton.vue'
    ),
);

// Status Icon
Vue.component('StatusIcon', () =>
    import(/* webpackChunkName: "status-icon" */ './components/StatusIcon.vue'),
);

// PDF Viewer
Vue.component('PdfViewer', () =>
    import(/* webpackChunkName: "pdf-viewer" */ './components/PDFViewer.vue'),
);

// Calendar
Vue.component('VirtualCalendar', () =>
    import(
        /* webpackChunkName: "virtual-calendar" */ './components/Calendar.vue'
    ),
);

Vue.component('EntityFilesButton', () =>
    import(
        /* webpackChunkName: "entity-files-button" */ './components/Files/ListButton.vue'
    ),
);
Vue.component('EntityFilesList', () =>
    import(
        /* webpackChunkName: "entity-files-list" */ './components/Files/List.vue'
    ),
);

//Employees
Vue.component('EditEmployee', () =>
    import(
        /* webpackChunkName: "edit-employee" */ './components/Employees/Edit.vue'
    ),
);
Vue.component('AddEmployee', () =>
    import(
        /* webpackChunkName: "add-employee" */ './components/Employees/Add.vue'
    ),
);
Vue.component('EmployeeStatusActions', () =>
    import(
        /* webpackChunkName: "employee-status-actions" */ './components/Employees/StatusActions.vue'
    ),
);

// Users
Vue.component('UserForm', () =>
    import(/* webpackChunkName: "user-form" */ './components/Users/Form.vue'),
);
Vue.component('SuspendUser', () =>
    import(
        /* webpackChunkName: "suspend-user" */ './components/Users/Suspend.vue'
    ),
);
Vue.component('ShadowUser', () =>
    import(
        /* webpackChunkName: "shadow-user" */ './components/Users/Shadow.vue'
    ),
);

// Locations
Vue.component('LocationForm', () =>
    import(
        /* webpackChunkName: "location-form" */ './components/Locations/Form.vue'
    ),
);

// Incidents
Vue.component('IncidentForm', () =>
    import(
        /* webpackChunkName: "incident-form" */ './components/Incidents/Form.vue'
    ),
);
Vue.component('PrintIncident', () =>
    import(
        /* webpackChunkName: "print-incident" */ './components/Incidents/Print.vue'
    ),
);
// Vue.component('IncidentExtension', () =>
//     import(
//         /* webpackChunkName: "incident-extension" */ './components/Incidents/Extension.vue'
//     ),
// );
Vue.component('IncidentFollowup', () =>
    import(
        /* webpackChunkName: "incident-followup" */ './components/Incidents/Followup.vue'
    ),
);

// Agencies
Vue.component('AddAgency', () =>
    import(
        /* webpackChunkName: "add-agency" */ './components/Agencies/Add.vue'
    ),
);
Vue.component('EditAgency', () =>
    import(
        /* webpackChunkName: "edit-agency" */ './components/Agencies/Edit.vue'
    ),
);

// Incident Types
Vue.component('IncidentTypeForm', () =>
    import(
        /* webpackChunkName: "incident-type-form" */ './components/IncidentTypes/Form.vue'
    ),
);

// Event Types
Vue.component('EventTypeForm', () =>
    import(
        /* webpackChunkName: "event-type-form" */ './components/EventTypes/Form.vue'
    ),
);

// MCO
Vue.component('AddMco', () =>
    import(/* webpackChunkName: "add-mco-form" */ './components/MCOs/Add.vue'),
);
Vue.component('EditMco', () =>
    import(
        /* webpackChunkName: "edit-mco-form" */ './components/MCOs/Edit.vue'
    ),
);

// Waiver Types
Vue.component('WaiverTypeForm', () =>
    import(
        /* webpackChunkName: "waiver-type-form" */ './components/WaiverTypes/Form.vue'
    ),
);

// Clients
Vue.component('ClientForm', () =>
    import(
        /* webpackChunkName: "client-form" */ './components/Clients/Form.vue'
    ),
);
Vue.component('ClientIncidents', () =>
    import(
        /* webpackChunkName: "client-incidents" */ './components/Clients/Incidents.vue'
    ),
);
Vue.component('ClientNotifier', () =>
    import(
        /* webpackChunkName: "client-notifier" */ './components/Clients/Autocaller.vue'
    ),
);
Vue.component('ClientAssessments', () =>
    import(
        /* webpackChunkName: "client-assessments" */ './components/Assessments/List.vue'
    ),
);
Vue.component('ClientCheckIn', () =>
    import(
        /* webpackChunkName: "client-checkin" */ './components/Clients/Checkin.vue'
    ),
);
// Vue.component('IncidentsFollowUpList', () =>
//     import(
//         /* webpackChunkName: "incidents-follow-up-list" */ './components/Clients/IncidentsFollowUpList.vue'
//     ),
// );
Vue.component('ClientSupervisionFiles', () =>
    import(
        /* webpackChunkName: "client-supervision-files" */ './components/Clients/ListSupervisionFiles.vue'
    ),
);

// Portal Navigation
Vue.component('PortalNavigationForm', () =>
    import(
        /* webpackChunkName: "portal-navigation-form" */ './components/PortalNavigation/Form.vue'
    ),
);
Vue.component('DeleteNavigationButton', () =>
    import(
        /* webpackChunkName: "delete-navigation-button" */ './components/PortalNavigation/DeleteButton.vue'
    ),
);

// Companies
Vue.component('CompanyForm', () =>
    import(
        /* webpackChunkName: "company-form" */ './components/Companies/Form.vue'
    ),
);

// Change Password
Vue.component('ChangePasswordForm', () =>
    import(
        /* webpackChunkName: "change-password-form" */ './components/Auth/ChangePassword.vue'
    ),
);

// Applications
Vue.component('AdminCaregiverApplicationDetails', () => {
    return import(
        /* webpackChunkName: "admin-caregiver-application-details" */ './components/Admin/Applications/Applicant.vue'
    );
});

// Assessment Survey
Vue.component('ClientAssessmentSurveyWizard', () =>
    import(
        /* webpackChunkName: "assessment-survey-wizard" */ './components/Assessments/Survey.vue'
    ),
);

// Flu Report
Vue.component('DcwFluReport', () =>
    import(
        /* webpackChunkName: "flu-report-generator" */ './components/Employees/DcwFluReport.vue'
    ),
);

// Video Assessment
Vue.component('VideoInvite', () =>
    import(
        /* webpackChunkName: "video-invite" */ './components/TwoWayVideo/Invite.vue'
    ),
);
Vue.component('VideoCall', () =>
    import(
        /* webpackChunkName: "video-call" */ './components/TwoWayVideo/Live.vue'
    ),
);

Vue.component('UpdateAccountModal', () =>
    import(
        /* webpackChunkName: "update-account-modal" */ './components/UpdateAccount.vue'
    ),
);

// 2fa
Vue.component('SendTwoFactorCodeForm', () =>
    import(
        /* webpackChunkName: "send-two-factor-code-form" */ './components/TwoFactor/Send.vue'
    ),
);
Vue.component('VerifyTwoFactorCodeForm', () =>
    import(
        /* webpackChunkName: "verify-two-factor-code-form" */ './components/TwoFactor/Verify.vue'
    ),
);
Vue.component('ResendOtpCodeButton', () =>
    import(
        /* webpackChunkName: "resend-otp-code-button" */ './components/TwoFactor/ResendButton.vue'
    ),
);

// User Roles
Vue.component('RoleForm', () =>
    import(
        /* webpackChunkName: "role-form" */ './components/Roles/Form.vue'
    ),
);

// ReAssignLocation Button
Vue.component('ReassignApplicantLocationButton', () =>
    import(
        /* webpackChunkName: "reassign-applicant-location-button" */ './components/Admin/Applications/ReassignApplicantLocationButton.vue'
    ),
);

// Leads
Vue.component('LeadsForm', () =>
    import(
        /* webpackChunkName: "leads-form" */ './components/Leads/Form.vue'
    ),
);
Vue.component('LeadsTemplateForm', () =>
    import(
        /* webpackChunkName: "leads-template-form" */ './components/Leads/Template.vue'
    ),
);
Vue.component('LeadsNotes', () =>
    import(
        /* webpackChunkName: "leads-notes" */ './components/Leads/Notes.vue'
    ),
);
Vue.component('LeadsCorrespondences', () =>
    import(
        /* webpackChunkName: "leads-correspondences" */ './components/Leads/Correspondences.vue'
    ),
);
Vue.component('LeadsTeam', () =>
    import(
        /* webpackChunkName: "leads-team" */ './components/Leads/Teams.vue'
    ),
);
Vue.component('LeadsTasks', () =>
    import(
        /* webpackChunkName: "leads-tasks" */ './components/Leads/Tasks.vue'
    ),
);

// Client Satisfaction Report
Vue.component('ClientSatisfactionReport', () =>
    import(
        /* webpackChunkName: "satisfaction-report-generator" */ './components/Clients/SatisfactionReport.vue'
    ),
);

// Client Payment Agreement Form
Vue.component('SendClientPaymentAgreementForm', () =>
    import(
        /* webpackChunkName: "send-client-payment-agreement-form" */ './components/Clients/SendPaymentAgreement.vue'
    ),
);

Vue.component('ClientPaymentAgreementForm', () =>
    import(
        /* webpackChunkName: "client-payment-agreement-form" */ './components/Clients/PaymentAgreementForm.vue'
    ),
);

// Client Assessment Report
Vue.component('ClientAssessmentReport', () =>
    import(
        /* webpackChunkName: "assessment-report-generator" */ './components/Assessments/Report.vue'
    ),
);

// Client Assessment Report List - Dashboard
Vue.component('ClientAssessmentReportList', () =>
    import(
        /* webpackChunkName: "client-assessment-report-list" */ './components/Dashboard/ClientAssessmentReportList.vue'
    ),
);

// SortDataIcon
Vue.component('SortDataIcon', () =>
    import(
        /* webpackChunkName: "sort-data-icon" */ './components/SortDataIcon.vue'
    ),
);

// EmployeeDocumentChecklist
Vue.component('EmployeeDocumentChecklist', () =>
    import(
        /* webpackChunkName: "employee-document-checklist" */ './components/Employees/DocumentChecklist.vue'
    ),
);

// IncidentComments
Vue.component('IncidentComments', () =>
    import(
        /* webpackChunkName: "incident-comments" */ './components/Incidents/Comments.vue'
    ),
);

// Virtual Interview Questionnaire Responses
Vue.component('VirtualInterviewQuestionnaireResponses', () =>
    import(
        /* webpackChunkName: "virtual-interview-questionnaire-responses" */ './components/Admin/Applications/QuestionnaireResponses.vue'
    ),
);

// Hold Button
Vue.component('HoldButton', () =>
    import(
        /* webpackChunkName: "hold-button" */ './components/HoldButton.vue'
    ),
);

// Hold Status Icon
Vue.component('HoldStatusIcon', () =>
    import(
        /* webpackChunkName: "hold-status-icon" */ './components/HoldStatusIcon.vue'
    ),
);

Vue.component('ClientPopupVisit', () =>
    import(
        /* webpackChunkName: "client-popup-visit" */ './components/Clients/PopupVisit.vue'
    ),
);

// Virtual Interview Live Invite
// Vue.component('VirtualInterviewLiveInvite', () =>
//     import(
//         /* webpackChunkName: "virtual-interview-invite" */ './caregiver/virtual-interview/Invite.vue'
//     ),
// );

const { i18nConfig } = setupVueI18n();

const i18n = new VueI18n({
    ...i18nConfig,
});

const app = new Vue({
    el: '#app',
    i18n,
    beforeCreate() {
        
    }
});
