module.exports = {
    configure(toastr, config = {}) {
        toastr.options = {
            positionClass: 'toast-bottom-right',
        };

        if (config.requireJquery) {
            window.$ = window.jQuery = require('jquery');
        }

        return toastr;
    },
};
