export function setupVueI18n() {
    const dataLang = window.__fmh ? window.__fmh.dataLang : {};
    const { locale = 'en' } = dataLang;

    const i18nConfig = {
        locale,
        fallbackLocale: 'en',
        messages: {
            en: {
                virtual_interview_check_your_internet_connection_path:
                    'Check your internet connection and {clickContinueSlot}',
                virtual_interview_click_continue: 'Click to continue',
                virtual_interview_starts_in_msg_path:
                    'starts in {virtualInterviewStartsOrExpiresMinuteSlot}',
                virtual_interview_expires_in_msg_path:
                    'expires in {virtualInterviewStartsOrExpiresMinuteSlot}',
                virtual_interview_starts_or_expires_minute:
                    'less than a minute. | {count} minute. | {count} minutes.',
            },
            es: {
                virtual_interview_check_your_internet_connection_path:
                    'Verifique su conexión a Internet y {clickContinueSlot}',
                virtual_interview_click_continue: 'Haga clic para continuar',
                virtual_interview_starts_in_msg_path:
                    'comienza en {virtualInterviewStartsOrExpiresMinuteSlot}',
                virtual_interview_expires_in_msg_path:
                    'caduca en {virtualInterviewStartsOrExpiresMinuteSlot}',
                virtual_interview_starts_or_expires_minute:
                    'menos de un minuto. | {count} minuto. | {count} minutos.',
            },
        },
    };

    return {
        i18nConfig,
    };
}
