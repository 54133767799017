var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isAdding
      ? _c(
          "select",
          {
            staticClass: "form-control",
            attrs: { id: _vm.id, disabled: _vm.isLoadingItems },
            domProps: { value: _vm.value },
            on: { change: _vm.changeAction },
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("-- select --")]),
            _vm._v(" "),
            _vm._l(_vm.options, function (item) {
              return _c(
                "option",
                {
                  key: item.id,
                  domProps: { value: item.id, selected: item.selected },
                },
                [_vm._v("\n            " + _vm._s(item.label) + "\n        ")]
              )
            }),
            _vm._v(" "),
            _vm.canAddNewItem
              ? _c("option", { attrs: { value: "-1" } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getNewItemLabel) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isAdding
      ? _c("div", { staticClass: "col-12 p-0" }, [
          _c("div", { staticClass: "row m-0" }, [
            _c("div", { staticClass: "col-8 pl-0 pr-2" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.$v.form.name.$model,
                    expression: "$v.form.name.$model",
                    modifiers: { trim: true },
                  },
                ],
                ref: "newItemInput",
                staticClass: "form-control",
                attrs: { type: "text", placeholder: _vm.newItemPlaceholder },
                domProps: { value: _vm.$v.form.name.$model },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.$v.form.name,
                      "$model",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._v(" "),
              _vm.$v.form.name.$dirty && _vm.$v.form.name.$error
                ? _c("div", { staticClass: "input-error" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.getNameErrorMessage) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasError && _vm.hasMessage && _vm.formMessage
                ? _c("div", { staticClass: "input-error" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.formMessage) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            !_vm.isSubmitting
              ? _c("div", { staticClass: "col-4 pl-0 pr-0" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info",
                      on: { click: _vm.addNewItem },
                    },
                    [_c("i", { staticClass: "fa fa-check" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      on: { click: _vm.cancelAddNewItem },
                    },
                    [_c("i", { staticClass: "fa fa-close" })]
                  ),
                ])
              : _c(
                  "div",
                  { staticClass: "col-5 text-left pt-2 pl-0" },
                  [_c("loading-icon", { attrs: { title: "adding item..." } })],
                  1
                ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }