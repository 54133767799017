var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.containerClass },
    [
      _c("div", { staticClass: "bold" }, [
        _vm._v(_vm._s(_vm.searchTitle) + ":"),
      ]),
      _vm._v(" "),
      _vm.letter
        ? [
            _c(
              "a",
              { staticClass: "sort-letter", attrs: { href: _vm.route } },
              [_vm._v("Any")]
            ),
          ]
        : [_c("span", { staticClass: "sort-letter bold" }, [_vm._v("Any")])],
      _vm._v(" "),
      _vm._l(_vm.alphaArr, function (alpha, idx) {
        return _c("span", { key: alpha + "_" + idx }, [
          _vm.letter && _vm.letter === alpha
            ? _c("span", { staticClass: "pl-2 sort-letter bold" }, [
                _vm._v("\n            " + _vm._s(alpha) + "\n        "),
              ])
            : _c(
                "a",
                {
                  staticClass: "pl-2 sort-letter",
                  attrs: {
                    id: "filter-employee-last-name-" + alpha,
                    href: _vm.route + "?letter=" + alpha,
                  },
                },
                [_vm._v("\n            " + _vm._s(alpha) + "\n        ")]
              ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }