module.exports = (mayBeNormalizeData) => {
    window.axios = require('axios');

    window.axios.defaults.headers.common['X-CSRF-TOKEN'] =
        (window.Laravel || {}).csrfToken || '';

    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    if (mayBeNormalizeData) {
        window.axios.interceptors.response.use(
            (resp) => {
                return mayBeNormalizeData(resp);
            },
            function (error) {
                return Promise.reject(error.response);
            },
        );
    }
};
