<template>
    <div :class="elmClass">
        <img class="img-fluid" src="/img/loader.gif" :alt="title" /> {{ text }}
    </div>
</template>
<script>
export default {
    props: {
        text: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: 'loading',
        },
        'class-name': {
            type: String,
            default: 'text-center',
        },
    },
    data() {
        let elmClass = this.className;

        elmClass += ' loading';

        return {
            elmClass,
        };
    },
};
</script>
