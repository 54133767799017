<template>
    <form :action="submitRoute" method="get" @submit="startSearch">
        <div class="row m-0">
            <div class="col col-10 p-0 pr-2">
                <div class="input-group search-form">
                    <input
                        id="filter-employee-search-form"
                        v-model="searchTerm"
                        name="q"
                        :disabled="isSubmitting"
                        :class="{
                            'form-control': true,
                            'not-empty':
                                searchTerm &&
                                searchTerm.length &&
                                !isSubmitting,
                        }"
                        :placeholder="placeholder"
                    />
                    <div
                        v-if="searchTerm && !isSubmitting"
                        class="input-group-addon"
                    >
                        <button
                            type="button"
                            class="btn btn-link p-0"
                            @click="clearSearch"
                        >
                            <i class="fa fa-close" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="col col-2 p-0">
                <button
                    id="filter-employee-search-form-submit"
                    class="btn btn-success"
                    style="width: 70%"
                    :disabled="isSubmitting"
                >
                    <i class="fa fa-search">&nbsp;</i>
                </button>
            </div>
        </div>
    </form>
</template>
<script>
const MIN_SEARCH_VALUE = 2;

export default {
    props: {
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        'submit-route': {
            type: String,
            required: true,
        },
        'extra-params': {
            type: [Object, Array],
            default: null,
        },
    },
    data() {
        return {
            searchTerm: this.value,
            isSubmitting: false,
        };
    },
    methods: {
        getSearchParams() {
            const { extraParams } = this;

            let extraParamsStr = '';

            if (extraParams && Object.keys(extraParams).length > 0) {
                Object.keys(extraParams).map((key) => {
                    if (extraParams[key]) {
                        extraParamsStr += `&${key}=${extraParams[key]}`;
                    }
                });
            }

            extraParamsStr += `&search=1`;

            return extraParamsStr;
        },
        startSearch(e) {
            e.preventDefault();
            const { searchTerm } = this;

            if (
                searchTerm &&
                searchTerm.length &&
                searchTerm.length < MIN_SEARCH_VALUE
            ) {
                return;
            }

            window.location = `${
                this.submitRoute
            }?q=${searchTerm}${this.getSearchParams()}`;
        },
        clearSearch() {
            this.searchTerm = '';

            this.isSubmitting = true;
            const extraParams = this.getSearchParams();

            window.location = `${this.submitRoute}${
                extraParams ? '?' + extraParams : ''
            }`;
        },
    },
};
</script>
<style scoped>
.search-form .form-control {
    border-radius: 4px !important;
}
.search-form .form-control.not-empty {
    border-right: 0;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.search-form .input-group-addon {
    border-left: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    border-left: 0;
}

.search-form .input-group-addon .btn {
    border: 0;
    color: #bbb;
    margin-top: 5px;
    margin-right: 5px;
}

.search-form .input-group-addon .btn i {
    font-size: 16px;
}
</style>
