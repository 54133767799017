var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.elmClass }, [
    _c("img", {
      staticClass: "img-fluid",
      attrs: { src: "/img/loader.gif", alt: _vm.title },
    }),
    _vm._v(" " + _vm._s(_vm.text) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }