var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { action: _vm.submitRoute, method: "get" },
      on: { submit: _vm.startSearch },
    },
    [
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col col-10 p-0 pr-2" }, [
          _c("div", { staticClass: "input-group search-form" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchTerm,
                  expression: "searchTerm",
                },
              ],
              class: {
                "form-control": true,
                "not-empty":
                  _vm.searchTerm && _vm.searchTerm.length && !_vm.isSubmitting,
              },
              attrs: {
                id: "filter-employee-search-form",
                name: "q",
                disabled: _vm.isSubmitting,
                placeholder: _vm.placeholder,
              },
              domProps: { value: _vm.searchTerm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchTerm = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _vm.searchTerm && !_vm.isSubmitting
              ? _c("div", { staticClass: "input-group-addon" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link p-0",
                      attrs: { type: "button" },
                      on: { click: _vm.clearSearch },
                    },
                    [_c("i", { staticClass: "fa fa-close" })]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col col-2 p-0" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success",
              staticStyle: { width: "70%" },
              attrs: {
                id: "filter-employee-search-form-submit",
                disabled: _vm.isSubmitting,
              },
            },
            [_c("i", { staticClass: "fa fa-search" }, [_vm._v(" ")])]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }